import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'src/store/reducers';
import { BankPaymentMethod, IAppointment } from 'src/types';
import {
  apiGetUnpaidAppointments,
  apiGetUserPaymentMethods,
  apiPayBulkInvoices,
} from 'src/api/user';
import { Button, Link, Typography } from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import PayModal from './PayModal';

export default function UnpaidAppointments(): ReactElement {
  const user = useSelector((state: IReduxState) => state.auth.user);
  const [filtered, setFiltered] = useState<IAppointment[]>([]);
  const [requested, setRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<BankPaymentMethod[]>([]);
  const [payModalOpen, setPayModalOpen] = useState(false);
  const [currentPayJob, setCurrentPayJob] = useState<IAppointment | null>(null);
  const [apptIds, setApptIds] = useState<number[]>([]);
  const [totalDue, setTotalDue] = useState(0);
  const [bulkResult, setBulkResult] = useState({
    success: '',
    error: '',
  });

  const loadData = useCallback(() => {
    setLoading(true);
    setRequested(true);

    apiGetUnpaidAppointments().then((resp) => {
      setFiltered(resp.data);
    });

    apiGetUserPaymentMethods()
      .then((resp) => {
        const accts = resp
          .filter((method) => method.type === 'us_bank_account')
          .map((method) => method.details as BankPaymentMethod);
        setBankAccounts(accts);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!requested) {
      loadData();
    }
  }, [loadData, requested]);

  const handlePay = (id: number) => {
    const job: IAppointment | undefined = filtered.find(
      (appt) => appt.id === id
    );

    if (job) {
      setCurrentPayJob(job);
      setPayModalOpen(true);
    }
  };

  const handlePayAll = () => {
    if (!user || !user.id) {
      setBulkResult({ success: '', error: 'User not found' });
      return;
    }

    apiPayBulkInvoices(apptIds, bankAccounts[0].id, totalDue, user.id).then(
      (res) => {
        if (res.errors) {
          setBulkResult({ success: '', error: res.errors.join(', ') });
        } else {
          setBulkResult({
            success: 'Successfully paid all invoices',
            error: '',
          });
          loadData();
          setApptIds([]);
        }
      }
    );
  };

  const serviceDisplay = (appt: IAppointment) => {
    let str = '';
    if (appt.attributes.appointment_type === 'diagnosis') {
      str += 'Diagnosis';
    }

    if (appt.attributes.estimate) {
      const { services } = appt.attributes.estimate;

      const strings: string[] = [];
      Object.keys(appt.attributes.estimate.services).forEach((key) => {
        if (
          services[key].status === 'approved' ||
          services[key].status === 'locked'
        ) {
          strings.push(`${key}`);
        }
      });

      if (strings.length > 0) {
        if (appt.attributes.appointment_type === 'diagnosis') {
          str += '. ';
        }
        str += 'Repairs: ';
        str += strings.join(', ');
      }
    }

    return str;
  };

  const rows = filtered.map((appt, index) => {
    const inspectionDoc = appt.attributes.documents.find(
      (doc) => doc.name === 'Inspection'
    );
    const diagReceipt = appt.attributes.documents.find(
      (doc) => doc.name === 'Diagnosis Receipt'
    );
    const repairReceipt = appt.attributes.documents.find(
      (doc) => doc.name === 'Repair Receipt'
    );

    return {
      id: index,
      appointment_id: appt.id,
      service: serviceDisplay(appt),
      date: appt.attributes.appointment_day,
      vehicle: `${appt.attributes.car.year} ${appt.attributes.car.make} ${appt.attributes.car.model}`,
      vin: appt.attributes.car.vin,
      inspection: inspectionDoc,
      receipt: diagReceipt,
      repair_receipt: repairReceipt,
      total_due:
        (appt.attributes.total_due.diagnosis?.total || 0) +
        (appt.attributes.total_due.estimate?.total || 0),
    };
  });

  const columns: GridColDef[] = [
    {
      field: 'pay',
      headerName: 'Pay',
      width: 100,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handlePay(params.row.appointment_id)}
          style={{ height: 30, fontSize: 12 }}
        >
          Pay
        </Button>
      ),
    },
    { field: 'service', headerName: 'Service', width: 450, filterable: true },
    { field: 'date', headerName: 'Date', width: 180 },
    { field: 'vehicle', headerName: 'Vehicle', width: 250 },
    { field: 'vin', headerName: 'VIN', width: 250 },
    {
      field: 'inspection',
      headerName: 'Inspection',
      width: 130,
      valueFormatter: (params) => params.value?.url || '',
      renderCell: (params: GridRenderCellParams) =>
        params.value && params.value.url ? (
          <Link
            href={params.value.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            View
          </Link>
        ) : (
          <></>
        ),
    },
    {
      field: 'receipt',
      headerName: 'Diag Receipt',
      width: 130,
      valueFormatter: (params) => params.value?.url || '',
      renderCell: (params: GridRenderCellParams) =>
        params.value && params.value.url ? (
          <Link
            href={params.value.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            View
          </Link>
        ) : (
          <></>
        ),
    },
    {
      field: 'repair_receipt',
      headerName: 'Repair Receipt',
      width: 130,
      valueFormatter: (params) => params.value?.url || '',
      renderCell: (params: GridRenderCellParams) =>
        params.value && params.value.url ? (
          <Link
            href={params.value.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            View
          </Link>
        ) : (
          <></>
        ),
    },
    {
      field: 'total_due',
      headerName: 'Total Due',
      width: 130,
      valueFormatter: (params) => `$${params.value}`,
      renderCell: (params: GridRenderCellParams) => (
        <Typography style={{ fontFamily: 'Inter' }}>
          {params.value ? `$${params.value}` : ''}
        </Typography>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: '0.1px solid lightgrey',
          }}
        >
          <Typography
            style={{ fontFamily: 'Inter', fontSize: '28px', margin: 10 }}
          >
            Unpaid Services
          </Typography>

          <GridToolbarContainer>
            <GridToolbarFilterButton style={{ color: '#344054' }} />
            <GridToolbarExport
              style={{ color: '#344054' }}
              csvOptions={{ fileName: 'goodhood-services' }}
            />
          </GridToolbarContainer>
        </div>

        {apptIds.length > 0 ? (
          <div
            style={{
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 10,
              borderBottom: '0.1px solid lightgrey',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ height: 30, fontSize: 12 }}
              onClick={handlePayAll}
            >
              Pay All (${totalDue})
            </Button>

            {bulkResult.success && (
              <Typography style={{ color: 'green', margin: 10 }}>
                {bulkResult.success}
              </Typography>
            )}

            {bulkResult.error && (
              <Typography style={{ color: 'red', margin: 10 }}>
                {bulkResult.error}
              </Typography>
            )}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DataGrid
        checkboxSelection
        onRowSelectionModelChange={(newSelection) => {
          const selectedIds = newSelection.map((id) => Number(id));

          let total = 0;
          const currApptIds = apptIds;
          selectedIds.forEach((id) => {
            const row = rows.find((r) => r.id === id);
            if (row) {
              currApptIds.push(row.appointment_id);
              total += row.total_due;
            }
          });

          setTotalDue(parseFloat(total.toFixed(2)));
          setApptIds(currApptIds);
        }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
        style={{ width: '100%' }}
        loading={loading}
        components={{
          Toolbar: CustomToolbar,
        }}
      />

      {currentPayJob && (
        <PayModal
          show={payModalOpen}
          onClose={() => setPayModalOpen(false)}
          job={currentPayJob}
          bankAccounts={bankAccounts}
          onSuccess={loadData}
        />
      )}
    </div>
  );
}
