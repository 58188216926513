import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Typography, Chip } from '@material-ui/core';

import { URLS } from 'src/utils/consts';
import { IReduxState } from 'src/store/reducers';
import { apiGetUser, apiGetUserAppointments } from 'src/api/user';
import './index.css';

import { IAppointment, IEstimate, IService, ResponseGetUser } from 'src/types';
import { FiberManualRecord } from '@material-ui/icons';

import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { logout, setUser } from 'src/store/actions';
import ViewEstimateModal from './ViewEstimateModal';
import ServiceModal from './ServiceModal';

const serviceDisplay = (appt: IAppointment) => {
  let str = '';

  if (
    appt.attributes.appointment_type === 'diagnosis' &&
    appt.attributes.estimate
  ) {
    str += 'Diagnosis';
  }
  if (
    appt.attributes.status === 'quote_requested' &&
    !appt.attributes.estimate
  ) {
    str += `${appt.attributes.problem_name}`;
  }

  if (appt.attributes.estimate) {
    const { services } = appt.attributes.estimate;
    Object.keys(services).forEach((key) => {
      if (services[key].status !== 'rejected') {
        str += str === '' ? `${key}` : `, ${key}`;
      }
    });
  }

  return str;
};

const statusDisplay: (
  status: IService,
  keys?: string[],
  index?: number
) => string | undefined = (status, keys = Object.keys(status), index = 0) => {
  if (index >= keys.length) return '';

  const key = keys[index];
  if (status[key].status === 'pending') {
    return 'pending';
  }

  if (status[key].status === 'approved') {
    return 'approved';
  }
  if (status[key].status === 'rejected') {
    return 'rejected';
  }

  return statusDisplay(status, keys, index + 1);
};

export default function UpcomingAppointments(): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [currEstimate, setCurrEstimate] = useState<IEstimate | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [currId, setCurrId] = useState<string | undefined>();
  const [diagnosisPresent, setDiagnosisPresent] = useState<boolean>(false);
  const [diagFee, setDiagFee] = useState<number>(0);

  const loggedIn = useSelector((state: IReduxState) => state.auth.loggedIn);
  if (!loggedIn) {
    history.push(URLS.LOGIN);
  }

  const [upcomingAppt, setUpcomingAppt] = React.useState<IAppointment[] | null>(
    null
  );

  const loadUpcoming = useCallback(() => {
    setLoading(true);

    apiGetUserAppointments('pending').then((resp) => {
      const appts = resp.data;

      if (!appts || appts.length === 0) {
        setUpcomingAppt([]);
      } else {
        setUpcomingAppt(appts);

        if (currEstimate) {
          const newCurrEst = appts.filter(
            (i) =>
              i.attributes.estimate &&
              i.attributes.estimate.id === currEstimate.id
          )[0];

          if (newCurrEst) setCurrEstimate(newCurrEst.attributes.estimate);
        }
      }
      setLoading(false);
    });
  }, [currEstimate]);

  useEffect(() => {
    loadUpcoming();
  }, [loadUpcoming]);

  useEffect(() => {
    const timerId = setTimeout(async () => {
      const userResp: ResponseGetUser = await apiGetUser();

      if (userResp && userResp.data) {
        dispatch(setUser(userResp.data));
      } else {
        dispatch(logout());
      }
    });
    return () => {
      clearTimeout(timerId);
    };
  }, [dispatch]);

  function CustomToolbar() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '0.1px solid lightgrey',
        }}
      >
        <Typography
          style={{ fontFamily: 'Inter', fontSize: '28px', margin: 10 }}
        >
          Upcoming Services
        </Typography>
        <GridToolbarContainer>
          <div style={{ marginRight: 'auto' }}>
            <GridToolbarFilterButton
              style={{ color: '#344054', marginRight: 10 }}
            />
          </div>
        </GridToolbarContainer>
      </div>
    );
  }

  const columns: GridColDef[] = [
    { field: 'vehicle', headerName: 'Vehicle', width: 340 },
    { field: 'vin', headerName: 'VIN', width: 220 },
    { field: 'date', headerName: 'Date', width: 140 },
    {
      field: 'arrivalWindow',
      headerName: 'Arrival Window',
      width: 210,
    },

    {
      field: 'service',
      headerName: 'Service',
      width: 200,
      renderCell: (params) => (
        <Typography
          style={{
            whiteSpace: 'pre-wrap',
            fontSize: params.value.length > 50 ? 13 : 16,
            fontFamily: 'Inter',
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => (
        <>
          {params.value.mainStatus === 'pending' && (
            <Chip
              icon={
                <FiberManualRecord
                  style={{ width: '15%', height: '50%', color: '#F5AE22' }}
                />
              }
              style={{
                background: '#FFF6D7',
                color: '#F5AE22',
                fontFamily: 'Inter',
                fontSize: 12,
              }}
              label={params.value.mainStatus}
            />
          )}
          {params.value.mainStatus === 'approved' &&
            !params.value.needsBooking && (
              <Chip
                icon={
                  <FiberManualRecord
                    style={{ width: '15%', height: '50%', color: '#037847' }}
                  />
                }
                style={{
                  background: '#ECFDF3',
                  color: '#037847',
                  fontFamily: 'Inter',
                  fontSize: 12,
                }}
                label={params.value.mainStatus}
              />
            )}
          {params.value.mainStatus === 'rejected' && (
            <Chip
              icon={
                <FiberManualRecord
                  style={{ width: '15%', height: '50%', color: '#E74D4D' }}
                />
              }
              style={{
                background: '#FCE6E6',
                color: '#E74D4D',
                fontFamily: 'Inter',
                fontSize: 12,
              }}
              label={params.value.mainStatus}
            />
          )}
        </>
      ),
    },
    {
      field: 'estimate',
      headerName: 'Estimate',
      width: 130,
      renderCell: (params) =>
        params.value.appointment_type === 'repair' &&
        !params.value.estimate ? null : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (params.value.estimate)
                setCurrEstimate(params.value.estimate as IEstimate);

              if (params.value.needsBooking) {
                setCurrId(params.value.id);
                setIsServiceModalOpen(true);
              } else if (
                params.value.appointment_type === 'diagnosis' &&
                !params.value.needsBooking
              ) {
                setDiagnosisPresent(true);
                setDiagFee(params.value.diagFee);
                setOpenModal(true);
              } else {
                setDiagnosisPresent(false);
                setOpenModal(true);
              }
            }}
            style={{ fontSize: 14, fontFamily: 'Inter', textTransform: 'none' }}
          >
            View
          </Button>
        ),
    },
  ];

  upcomingAppt?.sort((a, b) => b.id - a.id);

  const rows =
    upcomingAppt?.map((i) => ({
      id: i.id,
      vehicle: `${i.attributes.car.year} ${i.attributes.car.make} ${i.attributes.car.model} ${i.attributes.car.engine_size}`,
      vin: i.attributes.car.vin,
      date: i.attributes.appointment_day,
      arrivalWindow: i.attributes.appointment_time,
      service: serviceDisplay(i),
      status: {
        mainStatus:
          (i.attributes.appointment_type === 'diagnosis' &&
            i.attributes.appointment_day &&
            i.attributes.appointment_time &&
            'approved') ||
          (!i.attributes.appointment_day &&
            !i.attributes.appointment_time &&
            'pending') ||
          (i.attributes.estimate?.services &&
            statusDisplay(i.attributes.estimate?.services)) ||
          'pending',
        needsBooking:
          !i.attributes.appointment_day && !i.attributes.appointment_time,
      },
      estimate: {
        estimate: i.attributes.estimate,
        appointment_type: i.attributes.appointment_type,
        needsBooking:
          !i.attributes.appointment_day && !i.attributes.appointment_time,
        id: i.id,
        diagFee: i.attributes.diagnosis_fee,
      },
    })) || [];

  const getRowHeight = () => {
    return 100;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5]}
        autoHeight
        style={{ width: '100%' }}
        loading={loading}
        getRowHeight={getRowHeight}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      <ViewEstimateModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        estimate={currEstimate}
        services={currEstimate?.services}
        onStatusChange={() => loadUpcoming()}
        diagnosis={diagnosisPresent}
        diagnosisFee={diagFee}
      />

      <ServiceModal
        isOpen={isServiceModalOpen}
        onClose={() => setIsServiceModalOpen(false)}
        id={currId}
      />
    </div>
  );
}
