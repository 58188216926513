import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Typography } from '@material-ui/core';

import { EditOutlined } from '@material-ui/icons';
import { BankPaymentMethod, CardPaymentMethod, PaymentMethod } from 'src/types';
import { apiGetUserPaymentMethods } from 'src/api/user';
import { IReduxState } from 'src/store/reducers';

import ModalUpdateCC from './UpdateCC';
import ModalAddBankAccount from './ModalAddBankAccount';

export default function PaymentMethods(): ReactElement {
  const user = useSelector((state: IReduxState) => state.auth.user);

  const [openCreditModal, setOpenCreditModal] = useState<boolean>(false);
  const [openBankModal, setOpenBankModal] = useState<boolean>(false);
  const [allPaymentMethods, setAllPaymentMethods] = useState<PaymentMethod[]>(
    []
  );
  const [searched, setSearched] = useState<boolean>(false);

  const handleConnectBank = () => {
    setOpenBankModal(true);
  };

  useEffect(() => {
    if (searched) {
      return;
    }

    apiGetUserPaymentMethods().then((resp) => {
      setAllPaymentMethods(resp);
      setSearched(true);
    });
  }, [searched]);

  return (
    <Card
      style={{ width: '100%', padding: 30, background: '#fff' }}
      elevation={0}
    >
      <Typography
        variant="h4"
        style={{ fontFamily: 'Inter', marginBottom: 40 }}
      >
        Saved Payment Information
      </Typography>

      <Typography
        variant="body2"
        style={{ fontFamily: 'Inter', margin: '20px 0' }}
      >
        Default Credit Card
      </Typography>
      <Card
        style={{
          maxWidth: 'fit-content',
          padding: 20,
          display: 'flex',
          gap: 36,
        }}
      >
        {user?.attributes.card_on_file && (
          <>
            <div style={{ alignSelf: 'flex-start' }}>
              <Typography style={{ fontFamily: 'Inter' }}>
                {user.attributes.card_on_file.brand} ending in{' '}
                {user.attributes.card_on_file.last4}
              </Typography>
              <Typography style={{ fontFamily: 'Inter' }}>
                Expires {user.attributes.card_on_file.exp_month}/
                {user.attributes.card_on_file.exp_year}
              </Typography>
            </div>

            <Button
              onClick={() => setOpenCreditModal(true)}
              style={{ alignSelf: 'flex-start' }}
            >
              <EditOutlined
                style={{ marginRight: 10, color: '#005959', height: '25px' }}
              />
              <Typography
                style={{
                  fontFamily: 'Inter',
                  textTransform: 'none',
                  color: '#005959',
                  fontSize: 16,
                }}
              >
                Edit
              </Typography>
            </Button>

            <ModalUpdateCC
              show={openCreditModal}
              onClose={() => setOpenCreditModal(false)}
            />
          </>
        )}
      </Card>

      <Typography
        variant="body2"
        style={{ fontFamily: 'Inter', margin: '20px 0' }}
      >
        Other Payment Methods
      </Typography>
      {allPaymentMethods.map((method) => {
        if (
          method.type === 'card' &&
          method.details.last4 !== user?.attributes.card_on_file.last4
        ) {
          const cardDetails = method.details as CardPaymentMethod;

          return (
            <Card
              key={`${cardDetails.brand}-${cardDetails.last4}`}
              style={{
                maxWidth: 'fit-content',
                padding: 20,
                display: 'flex',
                gap: 36,
                marginTop: 10,
              }}
            >
              <div style={{ alignSelf: 'flex-start' }}>
                <Typography style={{ fontFamily: 'Inter' }}>
                  {cardDetails.brand} ending in {cardDetails.last4}
                </Typography>
                <Typography style={{ fontFamily: 'Inter' }}>
                  Expires {cardDetails.exp_date}
                </Typography>
              </div>
            </Card>
          );
        }

        if (method.type === 'us_bank_account') {
          const bankDetails = method.details as BankPaymentMethod;

          return (
            <Card
              key={`bank-${bankDetails.last4}`}
              style={{
                maxWidth: 'fit-content',
                padding: 20,
                display: 'flex',
                gap: 36,
                marginTop: 10,
              }}
            >
              <div style={{ alignSelf: 'flex-start' }}>
                <Typography style={{ fontFamily: 'Inter' }}>
                  {bankDetails.bank_name} ending in {bankDetails.last4} (
                  {bankDetails.account_type})
                </Typography>
              </div>
            </Card>
          );
        }

        return null;
      })}

      <Button
        onClick={handleConnectBank}
        style={{ backgroundColor: '#005959', color: '#fff', marginTop: 20 }}
      >
        Connect A Bank Account
      </Button>

      <ModalUpdateCC
        show={openCreditModal}
        onClose={() => setOpenCreditModal(false)}
      />
      <ModalAddBankAccount
        show={openBankModal}
        onClose={() => setOpenBankModal(false)}
        email={user?.attributes.email || ''}
        name={user?.attributes.name || ''}
      />
    </Card>
  );
}
