import React, { ReactElement, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@material-ui/core';
import { Close, CreditCard } from '@material-ui/icons';

import { ButtonForward } from 'src/components/atoms';
import {
  BankPaymentMethod,
  IAppointment,
  ResponseAppointment,
} from 'src/types';
import { apiPayInvoice } from 'src/api/user';

interface PayModalProps {
  show: boolean;
  onClose: () => void;
  job: IAppointment;
  bankAccounts: BankPaymentMethod[];
  onSuccess: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
  },
  content: {
    minWidth: 600,
    [theme.breakpoints.down('sm')]: {
      minWidth: 500,
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 200,
      padding: theme.spacing(0),
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    margin: 0,
    textAlign: 'center',
    padding: theme.spacing(4),
    '& .title-icon': {
      color: '#C5C9DE',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actionContainer: {
    width: '100%',
    textAlign: 'right',
  },
  titleDatetime: {
    fontWeight: 900,
    fontSize: 19,
    lineHeight: '22.8px',
    marginLeft: theme.spacing(1),
  },
  boxInformation: {
    minWidth: '100%',
    background: '#EBF1FA',
    borderRadius: 6,
    padding: theme.spacing(4),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      background: 'transparent',
      borderRadius: 0,
      padding: 0,
    },
  },
  contentHolder: {
    minHeight: 250,
    borderTopRightRadius: 9,
    borderBottomRightRadius: 9,
    padding: theme.spacing(4),
    alignContent: 'flex-start',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      borderRadius: 9,
      minHeight: 500,
      padding: theme.spacing(2),
    },
  },
  success: {
    color: '#36D9A0',
  },
}));

const PayModal = (props: PayModalProps): ReactElement => {
  const { show, onClose, job, bankAccounts, onSuccess } = props;
  const classes = useStyles();
  const [errors, setErrors] = useState<string | undefined | null>(null);
  const [success, setSuccess] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);
  const [stripeRequestInProgress, setStripeRequestInProgress] = useState(false);

  const [selectedPayments, setSelectedPayments] = useState({
    diagnosis: false,
    estimate: false,
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPayments({
      ...selectedPayments,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    if (bankAccounts.length === 1 && !paymentMethod) {
      setPaymentMethod(bankAccounts[0].id);
    }
  }, [bankAccounts, paymentMethod]);

  const handleCharge = async () => {
    setStripeRequestInProgress(true);

    if (!paymentMethod) {
      setErrors('Please select a payment method');
      return;
    }

    if (!selectedPayments.diagnosis && !selectedPayments.estimate) {
      setErrors('Please select at least one amount');
      return;
    }

    if (
      selectedPayments.diagnosis &&
      job.attributes.total_due.diagnosis.total &&
      job.attributes.total_due.diagnosis.id
    ) {
      apiPayInvoice(
        job.attributes.total_due.diagnosis.id,
        paymentMethod,
        job.attributes.total_due.diagnosis.total
      )
        .then((res: ResponseAppointment) => {
          if (res.errors) {
            setErrors(res.errors.join(', '));
          } else {
            setSuccess(true);
          }
        })
        .catch((e) => {
          setErrors('Error paying out diagnosis');
        });
    }

    if (
      selectedPayments.estimate &&
      job.attributes.total_due.estimate.total &&
      job.attributes.total_due.estimate.id
    ) {
      apiPayInvoice(
        job.attributes.total_due.estimate.id,
        paymentMethod,
        job.attributes.total_due.estimate.total
      )
        .then((res: ResponseAppointment) => {
          if (res.errors) {
            setErrors(res.errors.join(', '));
          } else {
            setSuccess(true);
          }
        })
        .catch((e) => {
          setErrors('Error paying out estimate');
        });
    }

    onSuccess();

    setStripeRequestInProgress(false);
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const v = event.target.value as string;
    setPaymentMethod(v);
  };

  const bankSelected = bankAccounts.find((bank) => bank.id === paymentMethod);

  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
      className={classes.root}
    >
      <DialogTitle className={classes.title}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container>
          <Grid
            container
            item
            md={12}
            sm={12}
            xs={12}
            className={classes.contentHolder}
          >
            <Box key="payment-title" flexDirection="row" display="flex">
              <CreditCard color="primary" />
              <Typography className={classes.titleDatetime} noWrap>
                Pay Invoice #{job.id}
              </Typography>
            </Box>

            {bankAccounts.length > 1 ? (
              <Box key="payment" className={classes.boxInformation}>
                <Typography>Select payment method</Typography>
                <Select native value={paymentMethod} onChange={handleChange}>
                  {bankAccounts.map((bankAccount) => {
                    return (
                      <MenuItem key={bankAccount.id} value={bankAccount.id}>
                        {bankAccount.bank_name} - {bankAccount.last4} (
                        {bankAccount.account_type})
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            ) : null}

            {errors && (
              <Box key="payment" className={classes.boxInformation}>
                <Typography color="error">{errors}</Typography>
              </Box>
            )}
            <Box key="payment" className={classes.boxInformation}>
              <Typography>Total Due</Typography>

              {job.attributes.total_due.diagnosis?.total &&
              job.attributes.total_due.diagnosis.total > 0 ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedPayments.diagnosis}
                      onChange={handleCheckboxChange}
                      name="diagnosis"
                      color="primary"
                    />
                  }
                  label={`Diagnosis: $${job.attributes.total_due.diagnosis.total}`}
                />
              ) : null}

              {job.attributes.total_due.estimate?.total &&
              job.attributes.total_due.estimate.total > 0 ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedPayments.estimate}
                      onChange={handleCheckboxChange}
                      name="estimate"
                      color="primary"
                    />
                  }
                  label={`Repairs: $${job.attributes.total_due.estimate.total}`}
                />
              ) : null}
              {success && (
                <Typography className={classes.success}>
                  Charge submitted.
                </Typography>
              )}
            </Box>

            {bankSelected && (
              <FormHelperText>
                Paying with {bankSelected.bank_name} - {bankSelected.last4} (
                {bankSelected.account_type})
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <DialogActions className={classes.actionContainer}>
          <ButtonForward
            key="update-cc"
            title="Pay"
            size="large"
            rounded
            onClickHandler={handleCharge}
            disabled={stripeRequestInProgress}
            processing={stripeRequestInProgress}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

PayModal.defaultProps = {};

export default PayModal;
